<template>
  <div id="educator" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <div class="columns" v-else>
      <div class="column is-6 is-offset-3">
        <ValidationObserver ref="observer" v-slot="{ passes }">
          <form @submit.prevent="passes(submit)">
            <p class="title is-5 has-text-right">
              {{ eCourseWork.eCourseTopic.name }}
            </p>

            <SdSelect
              rules="required"
              label="E Course Topic"
              v-model="eCourseTopicId"
            >
              <option
                v-for="eCourseTopic in eCourseTopics"
                :key="eCourseTopic.id"
                :value="eCourseTopic.id"
              >
                {{ eCourseTopic.name }}
              </option>
            </SdSelect>

            <SdSelect
              rules="required"
              label="Course Type"
              v-model="eCourseWork.courseType"
            >
              <option
                v-for="courseType in courseTypes"
                :key="courseType.id"
                :value="courseType.id"
              >
                {{ courseType.name }}
              </option>
            </SdSelect>

            <SdInput
              type="number"
              rules="required|min:0"
              label="Duration"
              v-model="eCourseWork.duration"
              placeholder="Enter a topic"
            />

            <b-field label="Published">
              <b-switch v-model="eCourseWork.published">{{
                eCourseWork.published
              }}</b-switch>
            </b-field>

            <button
              type="submit"
              class="button btn-120 is-primary is-capitalized is-pulled-right"
            >
              Submit
            </button>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { fetchTerm } from '@/assets/js/app_info.js'
import SdInput from '../../components/SdInput'
import SdSelect from '../../components/SdSelect'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '../../assets/js/notification'

export default {
  data() {
    return {
      pageTitle: 'E Course Work',
      schoolId: null,
      skipQuery: true,
      eCourseTopics: [],
      eCourseTopicId: null,
      eCourseWork: {
        eCourseTopic: {
          name: null,
          eCourse: {
            id: null,
          },
        },
        published: false,
      },
      courseTypes: [
        { id: 'Essay', name: 'Essay' },
        { id: 'Multiple Choice', name: 'Multiple Choice' },
        { id: 'Combined', name: 'Combined' },
      ],
    }
  },
  apollo: {
    eCourseTopics: {
      query: gql`
        query ECourseTopicsQuery($schoolId: ID!) {
          eCourseTopics(schoolId: $schoolId) {
            id
            name
          }
        }
      `,
      variables() {
        return { schoolId: this.schoolId }
      },
      // pollInterval: 1000,
    },
  },
  components: {
    SdInput,
    SdSelect,
    ValidationObserver,
  },
  mounted() {
    this.$store.commit('setPageTitle', this.pageTitle)
    this.schoolId = parseInt(this.$route.params.school_id)

    if (this.$store.getters.getValue) {
      this.eCourseTopicId = parseInt(this.$store.getters.getValue)
    } else {
      this.eCourseTopicId = parseInt(sessionStorage.getItem('eCourseTopicId'))
    }

    if (this.$route.params.id === 'new') {
      this.$store.commit('setSubMenus', [
        {
          name: 'E Course Works',
          route: `/school/${this.schoolId}/e_course_works/${this.eCourseTopicId}`,
        },
      ])
    } else {
      this.skipQuery = false
    }

    this.$apollo.addSmartQuery('eCourseWork', {
      query: gql`
        query ECourseWorkQuery($id: Int!) {
          eCourseWork(id: $id) {
            id
            eCourseTopic {
              id
              name
              eCourse {
                id
              }
            }
            duration
            published
            courseType
          }
        }
      `,
      variables() {
        return { id: parseInt(this.$route.params.id) }
      },
      skip() {
        return this.skipQuery
      },
    })

    this.$apollo.queries.eCourseWork.refetch()
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation createECourseWorkQuery(
                $eCourseTopicId: Int!
                $courseType: String!
                $duration: Int!
                $published: Boolean!
              ) {
                createECourseWork(
                  input: {
                    eCourseTopicId: $eCourseTopicId
                    courseType: $courseType
                    duration: $duration
                    published: $published
                  }
                ) {
                  eCourseWork {
                    id
                    eCourseTopic {
                      id
                      eCourse {
                        id
                      }
                    }
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              eCourseTopicId: parseInt(this.eCourseTopicId),
              courseType: this.eCourseWork.courseType,
              duration: parseInt(this.eCourseWork.duration),
              published: this.eCourseWork.published,
            },
          })
          .then((response) => {
            const data = response.data.createECourseWork
            crudNotification(data.errors, 'Successfully created.').then(() => {
              this.$router.push(
                `/school/${this.schoolId}/e_course_works/${data.eCourseWork.eCourseTopic.id}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation updateECourseWorkQuery(
                $id: Int!
                $eCourseTopicId: Int!
                $courseType: String!
                $duration: Int!
                $published: Boolean!
              ) {
                updateECourseWork(
                  input: {
                    id: $id
                    eCourseTopicId: $eCourseTopicId
                    courseType: $courseType
                    duration: $duration
                    published: $published
                  }
                ) {
                  eCourseWork {
                    id
                    eCourseTopic {
                      id
                      eCourse {
                        id
                      }
                    }
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.$route.params.id),
              eCourseTopicId: parseInt(this.eCourseWork.eCourseTopic.id),
              courseType: this.eCourseWork.courseType,
              duration: parseInt(this.eCourseWork.duration),
              published: this.eCourseWork.published,
            },
          })
          .then((response) => {
            const data = response.data.updateECourseWork
            crudNotification(data.errors, 'Successfully updated.').then(() => {
              this.$router.push(
                `/school/${this.schoolId}/e_course_works/${data.eCourseWork.eCourseTopic.id}`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
}
</script>
